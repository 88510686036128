import React from "react";
import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
import "./maps-styled.css";

export default function Maps() {
    return (
        <div className="maps">
            <div className="content-maps">
                <iframe id="maps-size" title="google maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3668.8476186592025!2d-47.240997285030076!3d-23.139244484894125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf4cf6805e666d%3A0x433f6dcce19fd226!2sR.%20Frederico%20Magnusson%2C%20207%20-%20Comercial%20Vitoria%20Martini%2C%20Indaiatuba%20-%20SP%2C%2013347-624!5e0!3m2!1spt-BR!2sbr!4v1630347498576!5m2!1spt-BR!2sbr" loading="lazy"></iframe>
            </div>
            <div className="icons-inform">
                <div className="icon-info">
                    <IoLocationOutline size="2rem" color="#323232"/>
                    <span>Rua Frederico Magnusson, 207, CEP - 13347-624</span>
                </div>
                <div className="icon-info">
                    <IoCallOutline size="2rem" color="#323232"/>
                    <span>(00) 0000-0000</span>
                </div>
            </div>
        </div>
    );
}