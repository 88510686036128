import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Blindagem from './pages/blindagem';
import Home from './pages/home';
import Servicos from './pages/servicos';
import SobreNos from './pages/sobre';

export default function Routers() {
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/sobre-nos" component={SobreNos} />
                <Route path="/blindagem" component={Blindagem} />
                <Route path="/servicos" component={Servicos} />
            </Switch>
        </BrowserRouter>
    );
}