import React from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import './servicos.styled.css';
import '../../assets/styles/global.css';
import { IoBrush, IoBuildOutline, IoCalendar, IoCarSportSharp, IoChevronUpCircle, IoCog, IoHammer, IoMusicalNotes, IoRefresh, IoRepeat, IoVolumeMute } from "react-icons/io5";
import OldCar from '../../assets/images/old-car.jpg';
import Maps from "../../components/maps";

export default function Servicos() {
    return (
        <div className="servicos">
            <header>
                <Menu />
            </header>
            <section className="title-servicos">
                <div className="title-servicos-h2-icon">
                    <IoBuildOutline size="2.5rem" color="#fff" id="none-mobile" />
                    <h2>ASSISTÊNCIA TÉCNICA, MANUTENÇÃO E PÓS VENDA</h2>
                </div>
            </section>
            <section className="content-about-atmpv">
                <div className="content-atmpv">
                    <div className="servicos-especializados">
                        <h4>Veículos blindados requerem cuidados especiais e, para isso, oferecemos serviços especializados:</h4>
                        <div className="services-spc">
                            <div className="serv-indv">
                                <IoBrush color="#323232" size="2rem" id="shadown-icon" />
                                <span>Funilaria, pintura, mecânica e elétrica</span>
                            </div>
                            <div className="serv-indv">
                                <IoCalendar color="#323232" size="2rem" id="shadown-icon" />
                                <span>Revisão e blindagem</span>
                            </div>
                            <div className="serv-indv">
                                <IoCog color="#323232" size="2rem" id="shadown-icon" />
                                <span>Manutenção de máquinas de vidro</span>
                            </div>
                            <div className="serv-indv">
                                <IoVolumeMute color="#323232" size="2rem" id="shadown-icon" />
                                <span>Eliminação de ruídos internos</span>
                            </div>
                            <div className="serv-indv">
                                <IoHammer color="#323232" size="2rem" id="shadown-icon" />
                                <span>Martelinho especializado em blindados</span>
                            </div>
                            <div className="serv-indv">
                                <IoMusicalNotes color="#323232" size="2rem" id="shadown-icon" />
                                <span>Instalação de acessórios (som, Xenon e Insufilm)</span>
                            </div>
                            <div className="serv-indv">
                                <IoCarSportSharp color="#323232" size="2rem" id="shadown-icon" />
                                <span>Alinhamento e balanceamento</span>
                            </div>
                            <div className="serv-indv">
                                <IoChevronUpCircle color="#323232" size="2rem" id="shadown-icon" />
                                <span>Upgrade de pastilhas e discos de freio</span>
                            </div>
                            <div className="serv-indv">
                                <IoRefresh color="#323232" size="2rem" id="shadown-icon" />
                                <span>Substituição de molas e amortecedores</span>
                            </div>
                            <div className="serv-indv">
                                <IoRepeat color="#323232" size="2rem" id="shadown-icon" />
                                <span>Serviço de leva e traz com plataforma</span>
                            </div>
                        </div>
                    </div>
                    <h4>Sobre o serviço</h4>
                    <span id="animate-delay">Temos uma equipe de profissionais para solucionar qualquer tipo de problema
                        referente à blindagem. Dispomos de unidade móvel pronta para suportes/atendimentos
                        in loco. Sempre com agendamento para melhor atendê-lo.
                    </span>
                </div>
            </section>
            <div className="title-old-cars">
                <h2>Restaurações de carros antigos</h2>
            </div>
            <section className="old-cars">
                <div className="content-old">
                    <div className="div-separation-old">
                        <p>A restauração de carros antigos tornou-se o desejo de muitos fãs de modelos antigos,
                        criamos um projeto realista para sua restauração. </p>
                    </div>
                    <div className="div-separation-old">
                        <img src={OldCar} alt="carro_antigo" />
                    </div>
                </div>
            </section>
            <div className="align-maps">
                <Maps />
            </div>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}