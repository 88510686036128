import React from "react";
import '../../assets/styles/global.css';
import './home-styled.css';
import Menu from "../../components/menu";
import { Link } from "react-router-dom";
import { IoLocationSharp, IoStarSharp, IoTimerSharp } from "react-icons/io5";
import Jeep from '../../assets/images/image-home.jpg';
import MovieHome from '../../assets/video/videohome.mp4';
import Maps from "../../components/maps";
import Footer from "../../components/footer";

export default function Home() {
    return (
        <div className="home">
            <header>
                <Menu />
            </header>
            <section>
                <div className="background-initial">
                    <div className="black-transparent">
                        <h2>BEM-VINDO(A) À DW, SUA BLINDADORA DE CONFIANÇA</h2>
                        <Link to="/sobre-nos">Conheça Mais!</Link>
                    </div>
                </div>
            </section>
            <section className="content-home">
                <div className="blindagem">
                    <div className="separate-form text-info">
                        <p>Blindagem com UDura</p>
                        <span>BLINDAGEM NÍVEL III-A</span>
                        <span>Trabalhamos com dois sistemas de blindagem de eficiência
                            comprovada, parte opaca: UDura e aço e manta. Saiba mais sobre eles:
                        </span>
                        <span>Valorização na revenda pela garantia estendida e menor desgaste
                            dos itens de segurança como freios e suspensão
                        </span>
                        <span>Inovador sistema de desenvolvimento de peças com braço tridimensional (3D)
                            à laser a partir dos veículos originais garante que as partes modeladas tenham
                            seus encaixes perfeitos aos contornos e interiores dos veículos, com projeto específico
                            de cada modelo de carro.</span>
                    </div>
                    <div className="separate-form">
                        <img src={Jeep} alt="carro_preto" />
                    </div>
                </div>
                <div className="beneficios">
                    <div className="title">
                        <p>Alguns dos nossos benefícios</p>
                    </div>
                    <div className={"icons-benefits"}>
                        <div className="icon-benefit">
                            <IoLocationSharp color="#323232" size="2.75rem" id="icon-shadow"/>
                            <span>Estamos próximo a você de todas as formas</span>
                        </div>
                        <div className="icon-benefit">
                            <IoStarSharp color="#323232" size="2.75rem" id="icon-shadow"/>
                            <span>Temos somente profissionais qualificados </span>
                        </div>
                        <div className="icon-benefit">
                            <IoTimerSharp color="#323232" size="2.75rem" id="icon-shadow"/>
                            <span>Não gostamos de fazer você perder o seu tempo</span>
                        </div>
                    </div>
                </div>
            </section>
            <section className="video-home">
                <div className="black-transparent">
                    <div className="video-play">
                        <video autoPlay muted controls loop>
                            <source id="size" src={MovieHome} />
                            Your browser does not support the video tag.
                        </video>
                        {/* <iframe title="Um vídeo para teste" src="https://www.youtube.com/embed/phi3WvteO-Y?mute=1&controls=0"></iframe> */}
                    </div>
                </div>
            </section>
            <div className="align-maps">
                <Maps />
            </div>
            <footer>
                <Footer />
            </footer>
        </div>
    );
}